<template>
  <div>
    <form @submit.prevent="updateDetail(deficiency,'general')">
      <div class="row justify-content-between">
        <div class="col-12 text-right" v-if="isAllowed('psc.main.deficiency.overview.details-edit')">
          <template v-if="toEdit">
            <button class="e-btn e-btn-outlined-red ml-2" @click="cancelUpdate('general')" type="button">Cancel</button>
            <button class="e-btn e-btn-blue ml-2" type="submit" >Save</button>
          </template>
          <template v-else>
            <button class="e-btn e-btn-green ml-2 px-3" @click="editDetail" type="button">Edit Detail</button>
          </template>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td style="width: 25%">PSC Code <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td class="py-2">
            <button v-if="toEdit" type="button" class="btn border btn-block text-left btn-sm" data-target="#pscCode" data-toggle="modal">
             {{this.code.hasOwnProperty('id') ? '(' + this.code.code.toUpperCase() + ') ' +  this.code.description.toUpperCase() : '-- SELECT PSC CODE --' }}

              <span style="float: right">
                      <font-awesome-icon icon="chevron-down"/>
              </span>
            </button>
            <span v-else>{{deficiency.psc_code_id ? '('+deficiency.code.code.toUpperCase()+') '+ deficiency.code.description.toUpperCase() : ''}}</span>
          </td>
        </tr>
        <tr>
          <td>Deficiency Description <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td>
            <input type="text" v-if="toEdit"
                   v-model="deficiency.description"
                   name="description"
                   class="form-control form-control-sm" id="">
            <span v-else> {{deficiency.description | toUpper}}</span>
          </td>
        </tr>
        <tr>
          <td style="width: 25%" class="pl-5">Detail</td>
          <td>
            <vue-editor class="my-2" v-if="toEdit" :editor-toolbar="toolbar" v-model="deficiency.details">
            </vue-editor>
            <div v-else class="pre-wrap-txt textarea-display-card my-2"
                 v-html="deficiency.details || '' " />
          </td>
        </tr>
        <tr>
          <td style="width: 25%">Action Code <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td>
            <select v-if="toEdit" name="" id="" class="form-control form-control-sm mb-2" v-model="deficiency.psc_action_code_id">
              <option :value="null"> --- SELECT ACTION CODE ---</option>
              <option :value="actionCode.id" v-for="actionCode in pscActionCodeKeys ">
                ({{actionCode.code.toUpperCase()}}) {{actionCode.description.toUpperCase()}}
              </option>
            </select>
            <span v-else>{{deficiency.psc_action_code_id ? '('+deficiency.action_code.code.toUpperCase()+') '+ deficiency.action_code.description.toUpperCase() : ''}}</span>
          </td>
        </tr>
        <tr>
          <td style="width: 25%">Action Code (USCG1)</td>
          <td>
            <select v-if="toEdit" name="" id="" class="form-control form-control-sm mb-2" v-model="deficiency.uscg_code_one_id">
              <option :value="null"> --- SELECT ACTION CODE (USCG1) ---</option>
              <option :value="uscgCode.id" v-for="uscgCode in uscgCodes">
                {{uscgCode.code.toLowerCase()}}. {{uscgCode.name}}
              </option>
            </select>
            <span v-else>{{deficiency.uscg_code_one_id ? deficiency.uscg_code_one.code.toLowerCase()+'. '+ deficiency.uscg_code_one.name.toUpperCase() : ''}}</span>
          </td>
        </tr>
        <tr>
          <td style="width: 25%" >Action Code (USCG2)</td>
          <td>
            <select v-if="toEdit" name="" id="" class="form-control form-control-sm mb-2" v-model="deficiency.uscg_code_two_id">
              <option :value="null"> --- SELECT ACTION CODE (USCG2) ---</option>
              <option :value="uscgCode.id" v-for="uscgCode in uscgCodes">
                {{uscgCode.code.toLowerCase()}}. {{uscgCode.name}}
              </option>
            </select>
            <span v-else>{{deficiency.uscg_code_two_id ? deficiency.uscg_code_two.code.toLowerCase()+'. '+deficiency.uscg_code_two.name.toUpperCase() : ''}}</span>
          </td>
        </tr>
        <tr>
          <td style="width: 25%">Action Code (RECTIFIED)</td>
          <td>
            <select v-if="toEdit" name="" id="" class="form-control form-control-sm mb-2" v-model="deficiency.uscg_rectify_id">
              <option :value="null"> --- SELECT ACTION CODE (RECTIFIED) ---</option>
              <option :value="actionCode.id" v-for="actionCode in pscActionCodeKeys" v-if="actionCode.id == 1">
                ({{actionCode.code.toUpperCase()}}) {{actionCode.description.toUpperCase()}}
              </option>
            </select>
            <span v-else>{{deficiency.uscg_rectify_id ? '('+deficiency.uscg_code_rectify.code.toUpperCase()+') '+ deficiency.uscg_code_rectify.description.toUpperCase() : ''}}</span>
          </td>
        </tr>
      </table>
    </form>
    <hr>
    <table class="w-100">
      <tr>
        <td style="width: 25%">Attached Photo Before Rectified <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td class="py-3">
          <div class="row">
            <div class="col-12 py-2" v-if="toEdit">
              <button class="e-btn e-btn-green e-btn-sm px-3 py-1 mr-4" data-toggle="modal"
                      @click="setAttachmentType(4)" data-target="#generalAttachment"
                      v-if="isAllowed('psc.main.deficiency.overview.attachments-create') && isOwnVessel('pscReport')"
              >
                Add Attachment
              </button>
              <button class="e-btn e-btn-blue e-btn-sm px-3 py-1"
                      @click="downloadAll(4,deficiency,'id')"
                      v-if="isAllowed('psc.main.deficiency.overview.attachments-download-all') && isOwnVessel('pscReport') && beforeRecAttachments.length"
              >
                Download All Documents
              </button>
            </div>
            <div class="col-12">
            </div>
          </div>
          <div class="row">
            <div  v-if="beforeRecAttachments.length" class="col-lg-2 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                  v-for="(attachment, attachmentIndex) in beforeRecAttachments">
              <psc-image-component :type="4"
                                   delete-permission="psc.main.deficiency.overview.attachments-delete"
                                   view-permission="psc.main.deficiency.overview.attachments-view-download"
                                   download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                   @deleteEmitter="deleteGeneralAttachment"
                                   @viewImage="viewImage(attachmentIndex, beforeRecAttachments, {
                                         'path'      : 'thumbnail',
                                         'caption'   : 'name'
                                   })"
                                   :imageInfo="attachment">
              </psc-image-component>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Attached Photo After Rectified <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td class="py-2">
          <div class="row">
            <div class="col-12 py-2" v-if="toEdit">
              <button class="e-btn e-btn-green e-btn-sm px-3 py-1 mr-4" data-toggle="modal"
                      @click="setAttachmentType(5)" data-target="#generalAttachment"
                      v-if="isAllowed('psc.main.deficiency.overview.attachments-create') && isOwnVessel('pscReport')"
              >
                Add Attachment
              </button>
              <button class="e-btn e-btn-blue e-btn-sm px-3 py-1"
                      @click="downloadAll(5,deficiency,'id')"
                      v-if="isAllowed('psc.main.deficiency.overview.attachments-download-all') && isOwnVessel('pscReport') &&  afterRecAttachments.length"
              >
                Download All Documents
              </button>
            </div>
            <div class="col-12">
              <div class="row">
                <div  v-if="afterRecAttachments.length" class="col-lg-2 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                      v-for="(attachment, attachmentIndex) in afterRecAttachments">
                  <psc-image-component :type="5"
                                       delete-permission="psc.main.deficiency.overview.attachments-delete"
                                       view-permission="psc.main.deficiency.overview.attachments-view-download"
                                       download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                       @deleteEmitter="deleteGeneralAttachment"
                                       @viewImage="viewImage(attachmentIndex, afterRecAttachments, {
                                         'path'      : 'thumbnail',
                                         'caption'   : 'name'
                                       })"
                                       :imageInfo="attachment">
                  </psc-image-component>
                </div>
              </div>
            </div>
          </div>

        </td>
      </tr>
    </table>

    <div class="modal fade" id="generalAttachment" tabindex="-1">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Add Attachments</h6>
            <button type="button" class="close"  ref="closeGeneralAttachment" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="file" ref="attachments"  @change="addGeneralAttachments" name="attachments" id="attachments" accept="image/*" multiple>
          </div>
<!--          <div class="modal-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="modal fade" id="pscCode" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">SELECT PSC CODE</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body overflow-auto " style="height: 50vh">
              <template v-for="(node,index) in pscCodeKeys">
                <h6 class="h6">({{node.code}}) {{ node.description }}</h6>
                 <psc-action-code-component :psc-code="code" :dept="10 + index" :node="node" @setPscCode="setSelectedPscCode">

                 </psc-action-code-component>
              </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Select</button>
          </div>
        </div>
      </div>
    </div>

<!--    FOR IMAGE SLIDER OF ATTACHMENTS   -->
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>
</template>

<script>
import {AlertService} from "../../../../services/AlertService";
import AttachmentMixin from "../../../../mixins/attachmentMixin";
import {mapActions, mapGetters} from "vuex";
import PscActionCodeComponent from "./PscActionCodeComponent.vue";
import PscAttachment from "../PscAttachment.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
import pscDeficiencyMixin from "../../../../mixins/PscDeficiencyMixin";
import PscImageComponent from "@/components/modules/psc/PscImageComponent.vue";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";

export default {
  name: "PscDeficiencyDetail",
  mixins:[AttachmentMixin,permissionMixin,pscDeficiencyMixin, ImageSliderMixin],
  components:{PscImageComponent, PscActionCodeComponent,PscAttachment, ImageSlider},
  data(){
    return {
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      attachmentType:null,
    }
  },
  methods: {
    ...mapActions(['uploadPscAttachments','getPscCodes','getPscActionCodes','getUscgCodes']),

    setAttachmentType(type) {
      this.attachmentType = type
    },
    async addGeneralAttachments() {
      const formData = new FormData();
      if (this.$refs.attachments.files.length > 0) {
        Array.prototype.forEach.call(this.$refs.attachments.files, file => {
          formData.append('attachments[]', file)
        })
        formData.append('id', this.getByKey(this.pscReport, 'id'))
        formData.append('type[id]', this.getByKey(this.deficiency, 'id'))
        formData.append('type[type_id]', this.attachmentType)
        const response = await this.uploadPscAttachments(formData);
        if (response) {
          await this.getAttachments(this.attachmentType, this.deficiency, 'id')
          AlertService.successAlert('Attachment added successfully', 'Add General Attachments')
          this.$refs.attachments.value = null;
          this.$refs.closeGeneralAttachment.click()
        }
      }
    },
    async deleteGeneralAttachment(attachment){
      const file=attachment.attachment
      const type=attachment.type
      const response= await this.deleteFilePsc(file,type)
      if(response){
        AlertService.successAlert('Psc General Attachment deleted successfully','DELETE GENERAL ATTACHMENT')
        await this.getAttachments(type,this.deficiency,'id')
      }
    },

    setSelectedPscCode(value){
      this.code=value
      this.deficiency.psc_code_id=value.id;
    },

  },
  computed:{
    ...mapGetters(['pscCodeKeys','pscActionCodeKeys','beforeRecAttachments','uscgCodes','afterRecAttachments','deficiency'])
  }
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
